import { Box, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { LinkComp } from 'components/atoms';
import {
  BRAND_LOGO_SVG,
  LINK_APPLESTORE,
  LINK_DOWNLOAD_AUTO_DETECT,
  LINK_GOOGLEPLAY,
  LOGO_FOOTER_CONTACT,
  LOGO_FOOTER_LOCATION,
} from 'constants/Images';
import { ABOUT_US_URL, GUIDE_URL, PATH_BUYMED_CAREER, PATH_TS_FACEBOOK, PATH_TS_LINKED, PATH_TS_TELE_CAM } from 'constants/Paths';
import { PHONE_NUMBER_CONTACT } from 'constants/data/contact';
import { ADDRESS_BUSSINESS } from 'constants/data/mobile';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const FooterCam = ({ isMobile = false }) => {
  const { t: translateCommon } = useTranslation('common');
  const policies = useStore((state) => state.policies);

  useEffect(() => {
    const hoverAreas = document.getElementsByClassName('general_info');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < hoverAreas.length; i++) {
      const hoverArea = hoverAreas[i];
      const { id: hoverAreaId } = hoverArea;
      const imageId = hoverAreaId?.split('_')[0] || '';
      if (imageId !== '') {
        const image = document.getElementById(imageId);
        hoverArea?.addEventListener('mouseenter', () => {
          image.src = `/static/images/footer/${imageId}_active.svg`;
        });

        hoverArea?.addEventListener('mouseleave', () => {
          image.src = `/static/images/footer/${imageId}_inactive.svg`;
        });
      }
    }
  }, []);
  return (
    <div id="app-footer" className={clsx(!isMobile ? styles.footer : styles.mobile_footer)}>
      <Container style={{ maxWidth: '1328px', fontFamily: 'nunsans' }}>
        <Grid container justifyContent="space-between" direction={!isMobile ? 'row' : 'column'}>
          <Grid item xs={!isMobile ? 6 : 12}>
            <LinkComp href="/" padding="0px">
              <Image src={BRAND_LOGO_SVG} width="118" height="25" />
            </LinkComp>

            <div style={{ marginTop: '8px' }}>
              <Typography style={{ fontWeight: 'bold', fontFamily: 'nunsans' }}>Buymed Cambodia Co., Ltd</Typography>
            </div>
            <div style={{ marginTop: !isMobile ? '16px' : '12px', display: 'flex' }}>
              {!isMobile && (
                <div style={{ marginRight: '8px', width: '40px' }}>
                  <Image src={LOGO_FOOTER_LOCATION} width="40" height="40" />
                </div>
              )}
              <div style={{ flex: 1 }}>
                <Typography style={{ fontSize: '12px', fontFamily: 'nunsans' }}>{translateCommon('footer.company_address')}</Typography>
                <Typography className={styles.info_content}>{translateCommon('footer.full_company_address')}</Typography>
              </div>
            </div>

            <div style={{ marginTop: !isMobile ? '16px' : '12px', display: 'flex' }}>
              {!isMobile && (
                <div style={{ marginRight: '8px' }}>
                  <Image src={LOGO_FOOTER_CONTACT} width="40" height="40" />
                </div>
              )}
              <div>
                <Typography style={{ fontSize: '12px', fontFamily: 'nunsans' }}>{translateCommon('footer.hotline')}</Typography>
                <div style={{ display: 'flex', color: '#1B4BE5', fontSize: '14px', paddingTop: '4px' }}>
                  <a href="tel:098888437" target="_blank" rel="noreferrer" className={styles.info_content}>
                    {PHONE_NUMBER_CONTACT}
                  </a>
                </div>
              </div>
            </div>

            {!isMobile && (
              <div style={{ marginTop: '16px', display: 'flex' }}>
                <Image src="/static/images/footer/copyright.svg" width="16" height="16" />
                <Typography style={{ fontFamily: 'ggsr', fontSize: '12px', color: '#606060', marginLeft: '5px' }}>
                  {translateCommon('footer.copy_right')}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item container direction={!isMobile ? 'row' : 'column'} xs={!isMobile ? 6 : 12} justifyContent="flex-end">
            {policies?.length > 0 && (
              <Grid item xs={4} container direction="column" style={{ margin: !isMobile ? '0px' : '24px 0px' }}>
                <Typography className={styles.general_info_title}>{translateCommon('footer.policies')}</Typography>
                {policies.map((policy, index) => (
                  <Grid item container alignItems="center" className={styles.policyItem}>
                    <LinkComp href={`/policy?code=${policy?.code || ''}`} key={policy?.code || index}>
                      <span className={styles.policyName}>{policy?.name || ''}</span>
                    </LinkComp>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid item container xs={!isMobile ? 3 : 5} style={{ margin: !isMobile ? '0px' : '16px 0px' }}>
              <Grid item xs={12} container direction="column">
                <Typography className={styles.general_info_title}>{translateCommon('footer.general_info')}</Typography>
                <Grid item container alignItems="center" className={`${styles.general_info_item} general_info`} id="career_link">
                  <LinkComp
                    href={`${PATH_BUYMED_CAREER}/job-openings/?job__location_spec=cambodia-office-st-528-sangkat-boeung-kak-1-khan-toul-kork-phnom-penh`}
                  >
                    <ImageFallbackStatic
                      src="/static/images/footer/career_inactive.svg"
                      layout="fixed"
                      height="20px"
                      width="20px"
                      alt="linked-career"
                      id="career"
                    />
                    <span style={{ marginLeft: '10px' }}>{translateCommon('footer.career')}</span>
                  </LinkComp>
                </Grid>
                <Grid item container alignItems="center" className={`${styles.general_info_item} general_info`} id="aboutUs_link">
                  <LinkComp href={ABOUT_US_URL}>
                    <ImageFallbackStatic
                      src="/static/images/footer/aboutUs_inactive.svg"
                      layout="fixed"
                      height="20px"
                      width="20px"
                      alt="linked-connect"
                      id="aboutUs"
                    />
                    <span style={{ marginLeft: '10px' }}>{translateCommon('footer.about_us')}</span>
                  </LinkComp>
                </Grid>
                <Grid item container alignItems="center" className={`${styles.general_info_item} general_info`} id="information_link">
                  <LinkComp href={GUIDE_URL}>
                    <ImageFallbackStatic
                      src="/static/images/footer/information_inactive.svg"
                      layout="fixed"
                      height="20px"
                      width="20px"
                      alt="linked-connect"
                      id="information"
                    />
                    <span style={{ marginLeft: '10px' }}>{translateCommon('footer.user_guide')}</span>
                  </LinkComp>
                </Grid>
              </Grid>
              <Grid item xs={12} container style={{ marginTop: !isMobile ? '0px' : '12px' }}>
                <Typography className={styles.general_info_title}>{translateCommon('footer.payment_method')}</Typography>
                <Grid item alignItems="center" className={styles.paymentIcon} style={{ paddingRight: '8px' }}>
                  <ImageFallbackStatic
                    src="/icons/payment_method_aba_khqr.svg"
                    layout="fixed"
                    height="24px"
                    width="42px"
                    alt="ABA-KHQR"
                  />
                </Grid>
                <Grid item alignItems="center" className={styles.paymentIcon}>
                  <ImageFallbackStatic
                    src="/icons/payment_method_cod.svg"
                    layout="fixed"
                    height="24px"
                    width="42px"
                    alt="COD"
                  />
                </Grid>
                <Grid item alignItems="center" className={styles.paymentIcon}>
                  <ImageFallbackStatic
                    src="/icons/payment_method_transfer.svg"
                    layout="fixed"
                    height="24px"
                    width="42px"
                    alt="Transfer"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction={!isMobile ? 'row' : 'row-reverse'}
              xs={!isMobile ? 5 : 12}
              justifyContent={!isMobile ? 'space-between' : 'flex-end'}
            >
              <Grid item xs={!isMobile ? 12 : 4}>
                <Typography className={styles.general_info_title}>{translateCommon('footer.connect_with_us')}</Typography>
                <Box className={styles.connectIconGroup}>
                  <Box className={styles.connectIcon}>
                    <Link href={PATH_TS_FACEBOOK} passHref>
                      <a href={PATH_TS_FACEBOOK} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <ImageFallbackStatic src="/static/images/footer/fb.svg" height="36" width="36" alt="facebook-connect" />
                      </a>
                    </Link>
                  </Box>
                  <Box className={styles.connectIcon}>
                    <Link href={PATH_TS_LINKED} passHref>
                      <a href={PATH_TS_LINKED} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <ImageFallbackStatic src="/static/images/footer/linkedin.svg" height="36" width="36" alt="linked-connect" />
                      </a>
                    </Link>
                  </Box>
                  <Box className={styles.connectIcon}>
                    <Link href={PATH_TS_TELE_CAM} passHref>
                      <a href={PATH_TS_TELE_CAM} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <ImageFallbackStatic src="/static/images/footer/tele.svg" height="36" width="36" alt="tiktok-connect" />
                      </a>
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={!isMobile ? 12 : 7}>
                <Typography className={styles.general_info_title}>{translateCommon('footer.download_app')}</Typography>
                <div style={{ display: 'flex', paddingTop: 12 }}>
                  {!isMobile && (
                    <div style={{ marginRight: 12 }}>
                      <a href={LINK_DOWNLOAD_AUTO_DETECT} target="_blank" rel="noreferrer">
                        <Image src="/static/images/footer/qr.png" width="88" height="88" />
                      </a>
                    </div>
                  )}
                  <div>
                    <div>
                      <a href={LINK_APPLESTORE} target="_blank" rel="noreferrer">
                        <Image src="/static/images/footer/appstore-3.svg" width="107" height="37" />
                      </a>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <a href={LINK_GOOGLEPLAY} target="_blank" rel="noreferrer">
                        <Image src="/static/images/footer/ggplay.svg" width="107" height="35" />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterCam;
